import { useSession } from "next-auth/react";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { buttonVariants } from "~/components/ui/button";
import { cn } from "~/lib/utils";

export const Header = () => {
  const router = useRouter();

  const [route, setRoute] = React.useState<string | null>(null);
  React.useEffect(() => {
    setRoute(router.pathname);
  }, [router.pathname]);

  const { status } = useSession();

  return (
    <div
      className={cn(
        "sticky top-0 z-20 h-20 px-4 py-2 md:px-8",
        "flex w-full flex-col md:h-16 md:flex-row md:items-center md:py-0",
        "border-b bg-background",
      )}
    >
      <Link href="/" className="text-lg font-black text-foreground">
        GLIMPSES
      </Link>
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-2 md:ml-8">
          <Link
            href="/blog"
            className={cn(
              "shrink-0 rounded-full py-1 text-center md:w-24",
              "text-base font-medium",
              "text-muted-foreground/70 hover:bg-muted hover:text-foreground",
              route === "/blog" && "font-bold text-foreground",
            )}
          >
            Blog
          </Link>
          <Link
            href="/inspiration"
            className={cn(
              "shrink-0 rounded-full py-1 text-center md:w-24",
              "text-base font-medium",
              "text-muted-foreground/70 hover:bg-muted hover:text-foreground",
              route === "/inspiration" && "font-bold text-foreground",
            )}
          >
            Inspiration
          </Link>
        </div>
        <div>
          {status === "authenticated" ? (
            <Link
              href="/feed"
              className={cn(
                buttonVariants({ variant: "outline" }),
                "rounded-full px-6",
              )}
            >
              Go to Feed
            </Link>
          ) : (
            <Link
              href="auth/sign-in"
              className={cn(
                buttonVariants({ variant: "outline" }),
                "rounded-full px-6",
              )}
            >
              Sign In
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
