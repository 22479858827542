const STORAGE_KEY = "preferences";

export interface LocalStoragePrefs {
  outfit_ids: string[];
  outfits: {
    id: string;
    src: string;
  }[];
  outfit_types: string[];
  body_type: string;
  facial_hair: string;
  hair_colour: string;
  height_cm: number;
  motivation: string[];
  colors: string[];
  styles: string[];
  styling_experience: string;
  shopping_frequency: string;
  shopping_struggles: string[];
  skin_tone: string;
  country: string;
  price_range: string;
  age_range: string;
  ethnicity: string;
  photo_urls: {
    front: string;
    side: string;
  };
}
export type LocalStoragePrefKey = keyof LocalStoragePrefs;

export const localStoragePrefs = {
  read: (): Partial<LocalStoragePrefs> => {
    if (typeof window === "undefined") {
      return {};
    }
    const stateStr = localStorage.getItem(STORAGE_KEY);
    const state = stateStr
      ? (JSON.parse(stateStr) as unknown as LocalStoragePrefs)
      : {};

    return state;
  },
  write: (prefs: Partial<LocalStoragePrefs>) => {
    const state = localStoragePrefs.read();
    localStorage.setItem(STORAGE_KEY, JSON.stringify({ ...state, ...prefs }));
  },
  writeKey: <T extends LocalStoragePrefKey>(
    key: T,
    answer: LocalStoragePrefs[T],
  ) => {
    const state = localStoragePrefs.read();
    state[key] = answer;
    localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
  },
};
