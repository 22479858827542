import { CheckIcon } from "lucide-react";
import Link from "next/link";
import { useRef, useState } from "react";
import { localStoragePrefs } from "~/components/onboard/local-storage-prefs";
import { cn } from "~/lib/utils";
import { api } from "~/utils/api";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

// maybe this should be an enum
const DRESS_CODES = [
  "All Dress Codes",
  "Athleisure",
  "Streetwear",
  "Casual",
  "Smart Casual",
  "Business Casual",
  "Formal",
  "Vintage",
  "Rugged",
  "Bohemian",
];

interface Outfit {
  id: string;
  image_url: string;
}

// Initial outfits (all dress codes) should be passed from getStaticProps in the landing page
// Allows the page to be fully rendered without making the API call
interface Props {
  outfits: Outfit[];
}

export const OutfitDrawer = ({ outfits }: Props) => {
  const [dressCode, setDressCode] = useState("All Dress Codes");
  const [selected, setSelected] = useState<Outfit[]>([]);
  const ref = useRef<HTMLDivElement>(null);

  const isSelected = (id: string) =>
    selected.find((o) => o.id === id) !== undefined;

  const onClickOutfit = (outfit: Outfit) => {
    const updated = isSelected(outfit.id)
      ? selected.filter((o) => o !== outfit)
      : [...selected, outfit];
    setSelected(updated);
    localStoragePrefs.writeKey(
      "outfits",
      updated.map((o) => ({ id: o.id, src: o.image_url })),
    );
  };

  return (
    <div className="sticky z-20 -mt-8 w-full rounded-t-3xl bg-white">
      <div
        id="outfits"
        className={cn(
          "mx-auto w-full max-w-[1600px]",
          "flex flex-col items-center",
          "gap-4 px-3 py-4",
          "md:gap-8 md:px-24 md:py-12",
        )}
      >
        <div className="h-1 w-[60px] rounded-full bg-slate-200 md:hidden" />
        <div
          className={cn(
            "sticky top-0 z-20 w-full bg-white py-4",
            "lg:flex lg:items-center lg:justify-between lg:gap-4",
          )}
          ref={ref}
        >
          <div>
            <h2 className="text-lg font-semibold md:text-2xl">
              Select 5 or more outfits you like.
            </h2>
            <p className="text-sm text-slate-500">
              Your selections will be used to create personal recommendations.
            </p>
          </div>
          <div className="mt-3">
            <Select onValueChange={setDressCode}>
              <SelectTrigger className="w-full lg:w-[240px]">
                <SelectValue placeholder="All Dress Codes" />
              </SelectTrigger>
              <SelectContent className="h-56 overflow-y-auto">
                {DRESS_CODES.map((dressCode) => (
                  <SelectItem key={dressCode} value={dressCode}>
                    {dressCode}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
        <OutfitsGrid
          initial={outfits}
          dressCode={dressCode}
          isSelected={isSelected}
          onClickOutfit={onClickOutfit}
        />
      </div>
      <div className="flex w-full justify-center px-8 text-center font-medium text-primary">
        Explore more outfits by selecting from the dropdown menu above.
      </div>
      <FloatingContinue selected={selected.length} />
    </div>
  );
};

interface OutfitsGridProps {
  initial: Outfit[];
  dressCode: string;
  isSelected: (id: string) => boolean;
  onClickOutfit: (outfit: Outfit) => void;
}

const OutfitsGrid = ({
  initial,
  dressCode,
  isSelected,
  onClickOutfit,
}: OutfitsGridProps) => {
  const { data, isFetching } = api.outfits.listForLanding.useQuery(
    {
      dressCode: dressCode === "All Dress Codes" ? undefined : dressCode,
      limit: 30,
    },
    {
      enabled: dressCode !== "All Dress Codes",
      placeholderData: initial,
      staleTime: Infinity,
    },
  );

  if (isFetching) {
    return (
      <div className="grid w-full grid-cols-2 gap-3 md:grid-cols-3 md:gap-4 lg:gap-6 xl:grid-cols-4">
        {Array.from({ length: 20 }).map((_, i) => (
          <div
            key={i}
            className="aspect-[1/2] w-full animate-pulse rounded-md bg-slate-100"
          />
        ))}
      </div>
    );
  }

  return (
    <div className="grid w-full grid-cols-2 gap-3 md:grid-cols-3 md:gap-4 lg:gap-6 xl:grid-cols-4">
      {data?.map((outfit, i) => (
        <OutfitItem
          key={outfit.id}
          id={outfit.id}
          imageUrl={outfit.image_url}
          selected={isSelected(outfit.id)}
          onClick={() => onClickOutfit(outfit)}
          loading={i < 10 ? "eager" : "lazy"}
        />
      ))}
    </div>
  );
};

interface FloatingContinue {
  selected: number;
}

const FloatingContinue = ({ selected }: FloatingContinue) => {
  return (
    <div className={cn("sticky bottom-2 z-30 mt-12 px-4 md:px-8")}>
      <div className="mx-auto w-full max-w-[27rem] rounded-full bg-primary/50 p-3 shadow-lg backdrop-blur-lg">
        <Link
          className={cn(
            "w-full max-w-[26rem]",
            "flex h-11 items-center justify-center rounded-full font-semibold",
            "bg-primary text-primary-foreground",
            "transition-colors duration-300",
            selected === 0 &&
              "pointer-events-none bg-primary/50 text-muted-foreground",
          )}
          href="/onboard/overview"
        >
          Continue{" "}
          <span className="ml-1 font-normal">({selected} selected)</span>
        </Link>
      </div>
    </div>
  );
};

interface OutfitItemProps {
  id: string;
  imageUrl: string;
  selected: boolean;
  onClick: () => void;
  loading?: "lazy" | "eager";
}

const OutfitItem = ({
  id,
  imageUrl,
  selected,
  loading,
  onClick,
}: OutfitItemProps) => {
  return (
    <button
      className="group relative rounded-md bg-slate-100"
      key={id}
      onClick={onClick}
    >
      <img
        key={id}
        alt=""
        style={{ backgroundImage: `url('${imageUrl}')` }}
        className={cn(
          "w-full rounded-md bg-cover bg-center object-contain transition-transform duration-300",
          selected ? "scale-[0.92]" : "hover:scale-[1.01]",
        )}
        width={512}
        height={1024}
        loading={loading}
      />
      {selected && (
        <div
          className={cn(
            "absolute right-[7%] top-[6%] flex h-6 w-6 items-center justify-center",
            "rounded-md border bg-white shadow-md",
          )}
        >
          <CheckIcon className="h-4 w-4 text-primary" />
        </div>
      )}
    </button>
  );
};
