import { useEffect, useState } from "react";
import { cn } from "~/lib/utils";

interface Props {
  outfits: {
    id: string;
    image_url: string;
  }[];
}

export const OutfitsBackground = ({ outfits }: Props) => {
  const [activeIdx, setActiveIdx] = useState(0);

  useEffect(() => {
    const isSmall = window.matchMedia("(max-width: 768px)").matches;
    const interval = setInterval(() => {
      setActiveIdx((prev) => {
        const opts = outfits
          .slice(0, isSmall ? 6 : 10)
          .map((_, i) => i)
          .filter((i) => i !== prev);
        return opts[Math.floor(Math.random() * opts.length)]!;
      });
    }, 3000);

    return () => clearInterval(interval);
  });

  return (
    <div className="absolute left-0 top-24 -z-20 h-[75vh] w-full md:h-[70vh]">
      <div
        className={cn(
          "mx-auto h-full w-full max-w-[1600px]",
          "grid grid-cols-3 gap-3 lg:grid-cols-5",
          "px-4 md:px-12 lg:px-24",
        )}
      >
        {outfits.slice(0, 10).map((outfit, idx) => (
          <div
            key={outfit.id}
            className={cn(
              "relative h-full w-full overflow-hidden rounded-md bg-background",
              idx > 5 ? "hidden lg:block" : "block",
            )}
          >
            <img
              src={outfit.image_url}
              alt="Outfit"
              className={cn(
                "h-full w-full object-cover",
                "transition-opacity duration-1000 ease-in-out",
                idx === activeIdx ? "opacity-100" : "opacity-5",
              )}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
