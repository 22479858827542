import type { InferGetStaticPropsType } from "next";
import Head from "next/head";
import { useSearchParams } from "next/navigation";
import { FeaturesCarousel } from "~/components/landing-page/Features";
import { Header } from "~/components/landing-page/header";
import { Hero } from "~/components/landing-page/Hero";
import { HowItWorks } from "~/components/landing-page/how-it-works";
import { OutfitDrawer } from "~/components/landing-page/outfit-drawer";
import { OutfitsBackground } from "~/components/landing-page/outfits-background";
import { FBQ } from "~/components/tracking-provider";
import { cn } from "~/lib/utils";
import { outfitListForLanding } from "~/server/api/handlers/outfit/outfit-list-landing";

type Props = InferGetStaticPropsType<typeof getStaticProps>;

export default function Page({ outfits }: Props) {
  const searchParams = useSearchParams();
  const source = searchParams?.get("source");
  if (source === "facebook") {
    FBQ.track("ViewContent", { content_ids: ["landing_page_view"] });
  }

  return (
    <>
      <Head>
        <meta name="theme-color" content="#ffffff" />
        <title>Glimpses - Look, Feel, and Dress Better</title>
      </Head>
      <Header />
      <Hero />
      <OutfitsBackground outfits={outfits} />
      <HowItWorks />
      <div
        id="features"
        className={cn(
          "sticky top-0 flex cursor-default flex-col items-center justify-center rounded-t-xl",
          "pb-24 pt-16 md:pb-36 md:pt-24",
          "dark bg-black text-foreground",
        )}
      >
        <FeaturesCarousel />
      </div>
      <OutfitDrawer outfits={outfits} />
    </>
  );
}

export async function getStaticProps() {
  const outfits = await outfitListForLanding({ limit: 40 });

  return {
    props: { outfits },
    revalidate: 3600,
  };
}
