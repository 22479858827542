import { cn } from "~/lib/utils";
import Image from "next/image";
import {
  Carousel,
  type CarouselApi,
  CarouselContent,
  CarouselItem,
} from "~/components/ui/carousel";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

export const FeaturesCarousel = () => {
  const [activeIdx, setActiveIdx] = useState(0);
  const [api, setApi] = useState<CarouselApi>();
  const [autoplay, setAutoplay] = useState(false);
  const [progress, setProgress] = useState(0);
  const { ref, inView } = useInView({ threshold: 0.9 });

  useEffect(() => {
    if (!api) return;

    setActiveIdx(api.selectedScrollSnap());
    api.on("select", () => {
      setProgress(0);
      setActiveIdx(api.selectedScrollSnap());
    });
    // api.on("pointerDown", () => {
    //   setAutoplay(false);
    //   setHasInteracted(true);
    // });
  }, [api]);

  useEffect(() => {
    if (!api) return;
    if (!inView) return;

    setAutoplay(true);
  }, [inView, api]);

  useEffect(() => {
    if (!api) return;
    if (!autoplay) return;

    const dur = 8000;
    const interval = setInterval(() => {
      api.scrollTo((activeIdx + 1) % 4);
    }, dur);
    const progressInterval = setInterval(() => {
      const step = 100 / (dur / 200);
      setProgress((prev) => prev + step);
    }, 200);

    return () => {
      clearInterval(interval);
      clearInterval(progressInterval);
    };
  }, [autoplay, activeIdx, api]);

  return (
    <Carousel setApi={setApi} className="max-w-full">
      <div
        ref={ref}
        className={cn(
          "flex w-full flex-row items-center justify-center",
          "pb-8 sm:pb-12 lg:pb-24",
          "gap-4",
        )}
      >
        {Array.from({ length: 4 }).map((_, i) => (
          <button
            key={i}
            onClick={() => {
              // setHasInteracted(true);
              // setAutoplay(false);
              api?.scrollTo(i);
            }}
            className={cn(
              "relative h-2 w-16 overflow-hidden rounded-full bg-muted shadow",
              activeIdx === i && "bg-muted",
            )}
          >
            {activeIdx === i && (
              <div
                className="absolute inset-0 z-10 bg-primary transition-all duration-200"
                style={{ width: `${progress}%` }}
              />
            )}
          </button>
        ))}
      </div>
      <CarouselContent>
        <CarouselItem>
          <YourFaceOnOutfits />
        </CarouselItem>
        <CarouselItem>
          <StyleThatWorksForYou />
        </CarouselItem>
        <CarouselItem>
          <EverythingInOnePlace />
        </CarouselItem>
        <CarouselItem>
          <AdaptToAnySituation />
        </CarouselItem>
      </CarouselContent>
    </Carousel>
  );
};

interface FeatureCardProps {
  children: React.ReactNode;
}

const FeatureCard = ({ children }: FeatureCardProps) => {
  return (
    <div
      className={cn(
        "mx-auto w-full max-w-sm",
        "flex h-full flex-col items-center justify-center gap-6 px-6",
        "md:grid md:max-w-6xl md:grid-cols-2 md:place-items-center md:gap-12 md:px-12",
      )}
    >
      {children}
    </div>
  );
};

interface FeatureCardDescriptionProps {
  children: React.ReactNode;
}

const FeatureCardDescription = ({ children }: FeatureCardDescriptionProps) => {
  return <div className="w-full space-y-3 text-left">{children}</div>;
};

const FeatureCardHeader = ({ children }: FeatureCardDescriptionProps) => {
  return (
    <h3 className="text-2xl font-bold leading-tight md:text-4xl">{children}</h3>
  );
};

const FeatureCardSubHeader = ({ children }: FeatureCardDescriptionProps) => {
  return (
    <div className="tracking-wide text-muted-foreground md:mt-6 md:text-xl">
      {children}
    </div>
  );
};

const YourFaceOnOutfits = () => {
  return (
    <FeatureCard>
      <FeatureCardDescription>
        <FeatureCardHeader>
          <p>See How You Look</p>
          <p>In Your Outfits</p>
        </FeatureCardHeader>
        <FeatureCardSubHeader>
          <p>
            Our face swap technology lets you see how you would look in the
            outfits recommended to you.
          </p>
        </FeatureCardSubHeader>
      </FeatureCardDescription>
      <Image
        src="/features/faceswap.png"
        alt="Faceswap Feature"
        width={300}
        height={300}
        className="w-full max-w-md object-contain"
      />
    </FeatureCard>
  );
};

const StyleThatWorksForYou = () => {
  return (
    <FeatureCard>
      <FeatureCardDescription>
        <FeatureCardHeader>
          <p>Style,</p>
          <p>Personal For You</p>
        </FeatureCardHeader>
        <FeatureCardSubHeader>
          <p>
            Look better with outfits personalised for your skin colour, body
            type, and preferences.
          </p>
        </FeatureCardSubHeader>
      </FeatureCardDescription>
      <Image
        src="/features/outfits-recs.png"
        alt="Outfit Recommendations"
        width={300}
        height={300}
        className="w-full max-w-md object-contain"
      />
    </FeatureCard>
  );
};

const AdaptToAnySituation = () => {
  return (
    <FeatureCard>
      <FeatureCardDescription>
        <FeatureCardHeader>
          <p>Clothes For</p>
          <p>Any Situation.</p>
        </FeatureCardHeader>
        <FeatureCardSubHeader>
          <p>Find outfits and items for specific occasions.</p>
          <p className="mt-1">Explore how specific items work</p>
          <p>across different outfits.</p>
        </FeatureCardSubHeader>
      </FeatureCardDescription>
      <Image
        src="/features/matching-outfits.png"
        alt="Adapt to any situation"
        width={300}
        height={300}
        className="w-full max-w-md object-contain"
      />
    </FeatureCard>
  );
};

const EverythingInOnePlace = () => {
  return (
    <FeatureCard>
      <FeatureCardDescription>
        <FeatureCardHeader>
          <p>Price That</p>
          <p>Works For You.</p>
        </FeatureCardHeader>
        <FeatureCardSubHeader>
          <p>
            We aggregate 100s of independent and large brands, so you can find
            the perfect item at your preferred price.
          </p>
        </FeatureCardSubHeader>
      </FeatureCardDescription>
      <Image
        src="/features/products.png"
        alt="Everything in one place"
        width={300}
        height={300}
        className="w-full max-w-md object-contain"
      />
    </FeatureCard>
  );
};
