import { cn } from "~/lib/utils";

const PrimaryButtonCTA = () => {
  return (
    <button
      className={cn(
        "relative",
        "inline-flex items-center rounded-full font-semibold",
        "whitespace-nowrap px-8 py-2.5 text-sm",
        "bg-primary text-primary-foreground hover:bg-primary/90",
      )}
      onClick={() => {
        document.querySelector("#outfits")?.scrollIntoView({
          behavior: "smooth",
        });
      }}
    >
      Start Dressing Better
      <span className="absolute -right-4 -top-2 rotate-12 rounded-lg bg-blue-500 p-1 text-white">
        FREE
      </span>
    </button>
  );
};

export default PrimaryButtonCTA;
