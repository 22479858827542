import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { useEffect, useRef, useState } from "react";
import { cn } from "~/lib/utils";
import PrimaryButtonCTA from "./primary-button-cta";
import { useInView } from "react-intersection-observer";

const STEPS = [
  {
    title: "Onboard",
    description: "Specify body, price and outfit preferences.",
    video: "/videos/onboard.mp4",
  },
  {
    title: "Discover",
    description: "Discover outfits personalised for you.",
    video: "/videos/discover.mp4",
  },
  {
    title: "Search",
    description: "Find items in your price range.",
    video: "/videos/search.mp4",
  },
];

export const HowItWorks = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [hasInteracted, setHasInteracted] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const { ref: divRef, inView } = useInView({ threshold: 0.9 });
  const [progress, setProgress] = useState(0);

  const onSelectStep = (step: number) => {
    setActiveStep(step);
    setHasInteracted(true);
  };

  const onVideoEnd = () => {
    if (!hasInteracted) {
      setActiveStep((prev) => (prev + 1) % STEPS.length);
    }
    setProgress(0);
  };

  useEffect(() => {
    const cur = videoRef.current;
    if (!cur) return;
    cur.playbackRate = 2;
    if (inView) {
      void cur.play().catch(() => console.error("Failed to play video"));
    } else {
      cur.pause();
    }
  }, [inView, videoRef, activeStep]);

  return (
    <div
      className={cn(
        "mx-auto w-full max-w-[1600px]",
        "px-4 py-12 md:px-12 md:py-16 lg:p-24",
      )}
    >
      <div className="text-3xl font-bold">How It Works.</div>
      <div
        className={cn(
          "mt-6 flex flex-col-reverse gap-8",
          "lg:mt-8 lg:grid lg:grid-cols-2 lg:place-items-start lg:gap-12",
        )}
      >
        <ToggleGroup.Root
          className="flex h-full w-full flex-col gap-4"
          type="single"
          value={activeStep.toString()}
          onValueChange={(value) => {
            onSelectStep(Number(value));
          }}
          aria-label="How It Works Steps"
        >
          {STEPS.map((step, index) => (
            <ToggleGroup.Item
              key={index}
              value={index.toString()}
              className="w-full rounded-xl text-left outline-none focus-visible:ring-2 focus-visible:ring-ring"
            >
              <Step
                progress={
                  activeStep === index && !hasInteracted ? progress : undefined
                }
                active={activeStep === index}
                title={step.title}
                description={step.description}
                step={index + 1}
              />
            </ToggleGroup.Item>
          ))}
          <div className="mt-3 lg:mt-6">
            <PrimaryButtonCTA />
          </div>
        </ToggleGroup.Root>
        <div
          ref={divRef}
          style={{ aspectRatio: "1520 / 1080" }}
          className="h-fit w-full"
        >
          <video
            ref={videoRef}
            key={"video-" + activeStep}
            className="h-full w-full rounded-lg border object-cover shadow"
            muted
            loop={hasInteracted}
            autoPlay
            playsInline
            width="1520px"
            height="1080px"
            src={STEPS[activeStep]!.video}
            onEnded={onVideoEnd}
            onTimeUpdate={() => {
              const cur = videoRef.current;
              if (!cur) return;
              setProgress(cur.currentTime / cur.duration);
            }}
          />
        </div>
      </div>
    </div>
  );
};

interface StepProps {
  progress?: number;
  active: boolean;
  title: string;
  description: string;
  step: number;
}

const Step = ({ progress, active, title, description, step }: StepProps) => {
  return (
    <div
      className={cn(
        "group flex items-center gap-4 rounded-xl",
        "p-3 lg:p-4",
        "data-[state=on]:bg-muted",
        "transition-all duration-500 ease-in-out",
      )}
      data-state={active ? "on" : "off"}
    >
      <StepNumber
        progress={progress}
        step={step}
        className="hidden lg:inline-flex"
      />
      <div className="space-y-2 lg:space-y-1">
        <div className="inline-flex items-center gap-2">
          <StepNumber progress={progress} step={step} className="lg:hidden" />
          <div className="text-xl font-bold group-data-[state=off]:text-muted-foreground">
            {title}
          </div>
        </div>
        <div className="text-muted-foreground transition-all group-data-[state=off]:text-muted-foreground/60">
          {description}
        </div>
      </div>
    </div>
  );
};

interface StepNumber {
  progress?: number;
  className?: string;
  step: number;
}

export const StepNumber = ({ progress, className, step }: StepNumber) => {
  return (
    <div
      className={cn(
        "relative aspect-square w-8 shrink-0 overflow-hidden",
        "inline-flex items-center justify-center",
        "rounded-md font-bold",
        "lg:w-16 lg:rounded-xl lg:text-xl",
        "bg-background group-data-[state=off]:bg-muted group-data-[state=off]:text-muted-foreground",
        className,
      )}
    >
      {progress !== undefined && (
        <div
          className="absolute inset-0 bg-muted/80 transition-all duration-200"
          style={{ width: `${progress * 100}%` }}
        />
      )}
      <div className="z-10">{step}</div>
    </div>
  );
};
