import { cn } from "~/lib/utils";
import PrimaryButtonCTA from "./primary-button-cta";

export const Hero = () => {
  return (
    <div className="relative flex max-h-min min-h-[80vh] w-full flex-col items-center justify-center overflow-hidden text-center md:min-h-[70vh]">
      <WavesBackground />
      <div className="relative">
        <h1 className="text-4xl font-bold md:text-6xl">
          <p>Men,</p>
          <p>Look and Dress</p>
          <p>Better.</p>
        </h1>
        <h2 className="mt-3 tracking-wide text-muted-foreground md:text-xl">
          <p>Glimpses helps you discover</p>
          <p>what clothes work best for you.</p>
        </h2>
        <div className="mt-4 inline-flex w-full items-center justify-center md:mt-6">
          <PrimaryButtonCTA />
        </div>
        <div className="absolute inset-0 -z-10 -m-60 overflow-hidden rounded-full">
          <div
            className="h-full w-full"
            style={{
              WebkitBackdropFilter: "blur()",
              background:
                "radial-gradient(circle, " +
                [
                  "rgba(255,255,255,1) 0%",
                  "rgba(255,255,255,0.90) 40%",
                  "rgba(255,255,255,0.50) 50%",
                  "rgba(255,255,255,0.10) 60%",
                ].join(",") +
                ")",
            }}
          />
        </div>
      </div>
    </div>
  );
};

const WavesBackground = () => {
  return (
    <div
      className={cn(
        "absolute inset-0 -z-30 h-full w-full bg-transparent",
        "bg-[linear-gradient(to_right,#f1f5f990_1px,transparent_1px),linear-gradient(to_bottom,#f1f5f990_1px,transparent_1px)] bg-[size:44px_44px]",
      )}
    />
  );
};
